import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "../_store";
import { PersistGate } from "redux-persist/integration/react";
import { Route, Routes } from "react-router";
import "../ulits/scss/style.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../pages/Home";
import About from "../pages/About";
import AboutHistory from "../pages/About/AboutHistory";
import AboutChooseus from "../pages/About/AboutChooseus";
import BlogListing from "../pages/BlogListing";
import BlogDetails from "../pages/BlogListing/BlogDetails";
import Contact from "../pages/Contact";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarListing/CarDetails";
import Finance from "../pages/Finance";
import EuropeanInsurance from "../pages/European-Insurance";
import OrderSpanish from "../pages/European-Insurance/OrderSpanish";
import OrderFrench from "../pages/European-Insurance/OrderFrench";
import AboutTestimonials from "../pages/About/AboutTestimonials";
import Privacy from "../pages/Privacy";
import TermsAndCondition from "../pages/Terms&Condition";
import { configs } from "../config";
import { configes } from "../webData";
import { useEffect } from "react";
import { useState } from "react";
import CustomLoader from "../components/CustomeLoader";

export function ChildThemeLayout(props) {
  const [config, setConfig] = useState({});
  const [isLoding, setIsLoding] = useState(true);

  useEffect(() => {
    getConfigData();
  }, []);

  const getConfigData = () => {
    const query = "?section=socials&section=general";
    fetch(`${configs.localApiUrl}/Configurations/${configes.domainId}${query}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("config", JSON.stringify(data?.Result?.config));
        setConfig(data?.Result?.config);
        setIsLoding(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <div className="color-theme">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={true}>
            {isLoding && !config ? (
              <CustomLoader />
            ) : (
              <>
                <Header configData={config} />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/about-history" element={<AboutHistory />} />
                  <Route
                    path="/about-testimonials"
                    element={<AboutTestimonials />}
                  />
                  <Route path="/about-choose" element={<AboutChooseus />} />
                  <Route path="/blog-listing" element={<BlogListing />} />
                  <Route path="/blog-details" element={<BlogDetails />} />
                  <Route path="/car-listing" element={<CarListing />} />
                  <Route path="/car-details/:slug" element={<CarDetails />} />
                  <Route path="/finance" element={<Finance />} />
                  <Route
                    path="/european-insurance"
                    element={<EuropeanInsurance />}
                  />
                  <Route path="/order-spanish" element={<OrderSpanish />} />
                  <Route path="/order-french" element={<OrderFrench />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route
                    path="/terms-conditions"
                    element={<TermsAndCondition />}
                  />
                </Routes>
                <Footer configData={config} />
              </>
            )}
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
}
