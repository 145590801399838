import { HpiCheckConstant } from "../_constants/HPICheck.constant";
import { status } from "../_constants/commonLhd";

let initialState = {};
console.log(initialState);

export function HpiCheck(state = initialState, action) {
  switch (action.type) {
    case HpiCheckConstant.HPI_CHECK_REQUEST:
      return {
        ...state,
        HpiCheck_status: status.IN_PROGRESS,
        HpiCheck_data: null,
      };
    case HpiCheckConstant.HPI_CHECK_SUCCESS:
      return {
        ...state,
        HpiCheck_status: status.SUCCESS,
        HpiCheck_data: action.data,
      };
    case HpiCheckConstant.HPI_CHECK_FAILURE:
      return {
        ...state,
        HpiCheck_status: status.FAILURE,
        HpiCheck_data: action.data,
      };
    case HpiCheckConstant.GET_FRENCHDESCRIPTION_REQUEST:
      return {
        ...state,
        FrenchDescription_status: status.IN_PROGRESS,
        FrenchDescription_data: null,
      };
    case HpiCheckConstant.GET_FRENCHDESCRIPTION_SUCCESS:
      return {
        ...state,
        FrenchDescription_status: status.SUCCESS,
        FrenchDescription_data: action.data,
      };
    case HpiCheckConstant.GET_FRENCHDESCRIPTION_FAILURE:
      return {
        ...state,
        FrenchDescription_status: status.FAILURE,
        FrenchDescription_data: action.data,
      };
    case HpiCheckConstant.GET_SPANISHDESCRIPTION_REQUEST:
      return {
        ...state,
        SpanishDescription_status: status.IN_PROGRESS,
        SpanishDescription_data: null,
      };
    case HpiCheckConstant.GET_SPANISHDESCRIPTION_SUCCESS:
      return {
        ...state,
        SpanishDescription_status: status.SUCCESS,
        SpanishDescription_data: action.data,
      };
    case HpiCheckConstant.GET_SPANISHDESCRIPTION_FAILURE:
      return {
        ...state,
        SpanishDescription_status: status.FAILURE,
        SpanishDescription_data: action.data,
      };
    default:
      return state;
  }
}
