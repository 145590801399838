import { type } from "@testing-library/user-event/dist/type";
import { contactConstant } from "../_constants/contact.constants";
import { contactService } from "../_services/contact.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";

export const contactAction = {
  contact,
  office,
};

function contact(data) {
  console.log(10, data);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: contactConstant.CONTACT_REQUEST,
        data: null,
      })
    );
    contactService
      .contact({ ...data, tenantId: configes.domainId })
      .then(
        (responce) => {
          if (responce.Status === 200) {
            dispatch(
              dispatchFunction({
                type: contactConstant.CONTACT_SUCCESS,
                data: responce.Result.contact,
              })
            );
            alert.success(responce.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: contactConstant.CONTACT_FAILURE,
                data: responce.Message,
              })
            );
            alert.error(responce.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: contactConstant.CONTACT_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function office() {
  
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: contactConstant.OFFICE_REQUEST,
        data: null,
      })
    );
    // console.log("Fetching office data...");
    contactService
      .office()
      .then(
        (response) => {
          // console.log("Fetched office data: ", response);
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: contactConstant.OFFICE_SUCCESS,
                data: response.Result.office,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: contactConstant.OFFICE_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          // console.log("Error fetching office data: ", error);
          dispatch(
            dispatchFunction({
              type: contactConstant.OFFICE_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        // console.log("Caught error: ");
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
