import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../_constants/commonLhd";
import { pageAction } from "../../_actions";
import { configs } from "../../config";
import parse from "html-react-parser";

function About() {
  const [loader, setLoading] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const pageData = useSelector((state) => state.page);

  useEffect(() => {
    if (pageData && pageData.page_status === status.SUCCESS) {
      setPage(pageData.page_data);
    }
  }, [pageData.page_data]);

  useEffect(() => {
    dispatch(pageAction.getPages());
  }, []);

  const handleButtonClick = (aboutButtonLink) => {
    if (aboutButtonLink.startsWith("http")) {
      window.open(aboutButtonLink, "_blank");
    } else {
      navigate(aboutButtonLink);
    }
  };

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>{page.aboutTitle}</h1>
                  {parse(page.aboutDescription)}
                  <button
                    className="primary-btn sm"
                    onClick={() => handleButtonClick(page.aboutButtonLink)}
                  >
                    {page.aboutTextButton}
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${page.aboutImage}`}
                alt="home-col-1"
              />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div
                className="about-item-box"
                onClick={() =>
                  navigate(`/about-history`, {
                    state: page,
                  })
                }
              >
                <div className="about-list-box">
                  {parse(page && page.aboutBlog1)}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.aboutImage1}`}
                    alt="about-col-2"
                  />
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.aboutImage2}`}
                    alt="about-col-2"
                  />
                </div>
                <div className="about-list-box">
                  {parse(page && page.aboutBlog2)}
                </div>
              </div>
              <div
                className="about-item-box"
                onClick={() => navigate("/about-history")}
              >
                <div className="about-list-box">
                  {parse(page && page.aboutBlog3)}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${page.aboutImage3}`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default About;
