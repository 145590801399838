import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import frenchimg from "../../ulits/images/french-img.png";
import { useDispatch, useSelector } from "react-redux";
import { HPICheckAction } from "../../_actions/HPICheck.action";
import { useNavigate } from "react-router";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";

function OrderFrench() {
  const [loading, setLoading] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const HpiData = useSelector((state) => state.HpiCheck);
  const [descripation, setDescripation] = useState({});
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(HPICheckAction.getFrenchDescription());
  }, []);

  useEffect(() => {
    if (HpiData?.FrenchDescription_status === status.SUCCESS) {
      setDescripation(HpiData.FrenchDescription_data);
    }
  }, [HpiData.FrenchDescription_data]);

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      message: validObj,
      isValid,
    };

    if (isValidate) {
      const { name, email, phone, message } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Please enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone is required",
          isValid: false,
        };
        isValid = false;
      } else if (!PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Please enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!message) {
        retData.message = {
          message: "Message is required",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) return;
    setCredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(HPICheckAction.createFrench(credentials));
      navigate("/");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const error = validateCredentials(isValidate);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper order-french">
          {descripation &&
            descripation.length > 0 &&
            descripation.map((descripation, index) => (
              <div key={index} className="finance-section">
                <div className="order-title">
                  <div className="container">
                    <h1>{descripation.key}</h1>
                    <div className="order-french-row">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="order-spanish-img">
                            <img
                              src={`${configs.localApiUrl}${descripation.image}`}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="order-french-text">
                            <p>{descripation.value}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="finance-title">
                  <h1>{descripation.key}</h1>
                  <p>{descripation.value}</p>
                </div> */}
              </div>
            ))}
          <div className="european-insurance-form">
            <div className="container">
              <h2>SUBMIT REQUEST FOR FRENCH HPI CHECK</h2>
              {/* {submitted && (
                <p style={{ color: "green" }}>Form submitted successfully!</p>
              )} */}
              <form>
                <div className="form-group">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={credentials.name}
                    onChange={handleChange}
                    placeholder="NAME"
                    required
                    className="form-control"
                  />
                  {error && error.name.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.name.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={credentials.email}
                    onChange={handleChange}
                    placeholder="EMAIL"
                    required
                    className="form-control"
                  />
                  {error && error.email.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.email.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={credentials.phone}
                    onChange={handleChange}
                    placeholder="PHONE"
                    required
                    className="form-control"
                  />
                  {error && error.phone.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.phone.message}
                    </div>
                  )}
                </div>
                <div className="form-group textarea">
                  <textarea
                    id="message"
                    name="message"
                    value={credentials.message}
                    onChange={handleChange}
                    placeholder="MESSAGE"
                    required
                  />
                  {error && error.message.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.message.message}
                    </div>
                  )}
                </div>
                <button
                  className="primary-btn sm"
                  type="SEND MESSAGE"
                  title="SEND MESSAGE"
                  onClick={handleSubmit}
                >
                  SEND MESSAGE
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderFrench;
