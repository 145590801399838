export const financeConstant = {
  GET_FINANCE_REQUEST: "GET_FINANCE_REQUEST",
  GET_FINANCE_SUCCESS: "GET_FINANCE_SUCCESS",
  GET_FINANCE_FAILURE: "GET_FINANCE_FAILURE",

  GET_fAQ_REQUEST: "GET_fAQ_REQUEST",
  GET_fAQ_SUCCESS: "GET_fAQ_SUCCESS",
  GET_fAQ_FAILURE: "GET_fAQ_FAILURE",

  GET_FINANCELOGO_REQUEST: "GET_FINANCELOGO_REQUEST",
  GET_FINANCELOGO_SUCCESS: "GET_FINANCELOGO_SUCCESS",
  GET_FINANCELOGO_FAILURE: "GET_FINANCELOGO_FAILURE",

  GET_FINANCEDESCRIPTION_REQUEST: "GET_FINANCEDESCRIPTION_REQUEST",
  GET_FINANCEDESCRIPTION_SUCCESS: "GET_FINANCEDESCRIPTION_SUCCESS",
  GET_FINANCEDESCRIPTION_FAILURE: "GET_FINANCEDESCRIPTION_FAILURE",
};
