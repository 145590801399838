import { homeConstant } from "../_constants/home.constant";
import { status } from "../_constants/commonLhd";

let initialState = {};
console.log(initialState);

export function home(state = initialState, action) {
  switch (action.type) {
    case homeConstant.GET_CARBYID_REQUEST:
      return {
        ...state,
        car_status: status.IN_PROGRESS,
        car_data: null,
      };
    case homeConstant.GET_CARBYID_SUCCESS:
      return {
        ...state,
        car_status: status.SUCCESS,
        car_data: action.data,
      };
    case homeConstant.GET_CARBYID_FAILURE:
      return {
        ...state,
        car_status: status.FAILURE,
        car_data: action.data,
      };

    case homeConstant.GET_ALLBRANDS_REQUEST:
      return {
        ...state,
        brands_status: status.IN_PROGRESS,
        brands_data: null,
      };
    case homeConstant.GET_ALLBRANDS_SUCCESS:
      return {
        ...state,
        brands_status: status.SUCCESS,
        brands_data: action.data,
      };
    case homeConstant.GET_ALLBRANDS_FAILURE:
      return {
        ...state,
        brands_status: status.FAILURE,
        brands_data: action.data,
      };

    case homeConstant.CAR_INQUIRY_REQUEST:
      return {
        ...state,
        inquiry_status: status.IN_PROGRESS,
        inquiry_data: null,
      };
    case homeConstant.CAR_INQUIRY_SUCCESS:
      return {
        ...state,
        inquiry_status: status.SUCCESS,
        inquiry_data: action.data,
      };
    case homeConstant.CAR_INQUIRY_FAILURE:
      return {
        ...state,
        inquiry_status: status.FAILURE,
        inquiry_data: action.data,
      };

    case homeConstant.EMAIL_REQUEST:
      return {
        ...state,
        email_status: status.IN_PROGRESS,
        email_data: null,
      };
    case homeConstant.EMAIL_SUCCESS:
      return {
        ...state,
        email_status: status.SUCCESS,
        email_data: action.data,
      };
    case homeConstant.EMAIL_FAILURE:
      return {
        ...state,
        email_status: status.FAILURE,
        email_data: action.data,
      };

    case homeConstant.TEST_DRIVE_REQUEST:
      return {
        ...state,
        test_drive_status: status.IN_PROGRESS,
        test_drive_data: null,
      };
    case homeConstant.TEST_DRIVE_SUCCESS:
      return {
        ...state,
        test_drive_status: status.SUCCESS,
        test_drive_data: action.data,
      };
    case homeConstant.TEST_DRIVE_FAILURE:
      return {
        ...state,
        test_drive_status: status.FAILURE,
        test_drive_data: action.data,
      };

    case homeConstant.TOP_BANNER_REQUEST:
      return {
        ...state,
        top_banner_status: status.IN_PROGRESS,
        top_banner_data: null,
      };
    case homeConstant.TOP_BANNER_SUCCESS:
      return {
        ...state,
        top_banner_status: status.SUCCESS,
        top_banner_data: action.data,
      };
    case homeConstant.TOP_BANNER_FAILURE:
      return {
        ...state,
        top_banner_status: status.FAILURE,
        top_banner_data: action.data,
      };

      case homeConstant.FETCH_CAR_DETAILS_REQUEST:
        return {
          ...state,
          car_details_status: status.IN_PROGRESS,
          car_details_data: null,
        };
      case homeConstant.FETCH_CAR_DETAILS_SUCCESS:
        return {
          ...state,
          car_details_status: status.SUCCESS,
          car_details_data: action.data,
        };
      case homeConstant.FETCH_CAR_DETAILS_FAILURE:
        return {
          ...state,
          car_details_status: status.FAILURE,
          car_details_data: action.data,
        };

      default:
        return state;
    }
  }
