import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const financeService = {
  finance,
  getFaq,
  getFinanceLogo,
  getFinanceDescription,
};

function finance(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/applyForFinance?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getFaq() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getFaqs/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getFinanceLogo() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(`${configs.localApiUrl}/getFinanceLogo`, requestOptions).then(
    (response) => response.json()
  );
}

function getFinanceDescription() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getDiscriptionByTenantId/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}
