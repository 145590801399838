import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const HPICheckService = {
  createFrench,
  createSpanish,
  getFrenchDescription,
  getSpanishDescription,
};

function createFrench(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/french?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function createSpanish(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/spanish?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getFrenchDescription() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/frenchDiscriptionByTenantId/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getSpanishDescription() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/spanishDiscriptionByTenantId/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}
