import { homeConstant } from "../_constants/home.constant";
import { homeService } from "../_services/home.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";
import { data } from "react-router";

export const homeAction = {
  getCarsByTenantId,
  getBrandsByTenantId,
  carInquiry,
  sendEmail,
  testDrive,
  getTopBannerDetail,
  getCarDetail,
};

function getCarsByTenantId() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.GET_CARBYID_REQUEST,
        data: null,
      })
    );
    homeService
      .getCarsByTenantId()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_CARBYID_SUCCESS,
                data: response.Result.cars,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_CARBYID_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.GET_CARBYID_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}
function getBrandsByTenantId() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.GET_ALLBRANDS_REQUEST,
        data: null,
      })
    );
    homeService
      .getBrandsByTenantId()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_ALLBRANDS_SUCCESS,
                data: response.Result.brands,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.GET_ALLBRANDS_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.GET_ALLBRANDS_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function carInquiry(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.CAR_INQUIRY_REQUEST,
        data: null,
      })
    );
    homeService
      .carInquiry({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.CAR_INQUIRY_SUCCESS,
                data: response.Result.InquiriData,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.CAR_INQUIRY_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.CAR_INQUIRY_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function sendEmail(data) {
  console.log("sendEmail", data);

  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.EMAIL_REQUEST,
        data: null,
      })
    );
    homeService
      .sendEmail({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 201) {
            dispatch(
              dispatchFunction({
                type: homeConstant.EMAIL_SUCCESS,
                data: response,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.EMAIL_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.EMAIL_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function testDrive(data) {
  console.log(189, data);

  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.TEST_DRIVE_REQUEST,
        data: null,
      })
    );
    homeService
      .testDrive({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.TEST_DRIVE_SUCCESS,
                data: response,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.TEST_DRIVE_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.TEST_DRIVE_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getTopBannerDetail() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.TOP_BANNER_REQUEST,
        data: null,
      })
    );
    homeService
      .getTopBannerDetail()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.TOP_BANNER_SUCCESS,
                data: response.Result.topBannerDetail,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.TOP_BANNER_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.TOP_BANNER_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getCarDetail(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: homeConstant.FETCH_CAR_DETAILS_REQUEST,
        data: null,
      })
    );
    console.log("294 data", data);
    console.log("295", { ...data, tenantId: configes.domainId});

    homeService
      // .getCarDetail({ ...data, tenantId: configes.domainId })
      .getCarDetail(data)
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: homeConstant.FETCH_CAR_DETAILS_SUCCESS,
                data: response.Result.cars,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: homeConstant.FETCH_CAR_DETAILS_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: homeConstant.FETCH_CAR_DETAILS_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function dispatchFunction(data) {
  console.log(149, data);
  return {
    type: data.type,
    data: data.data,
  };
}
