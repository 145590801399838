import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomeLoader";

const TermsAndCondition = () => {
  const [loader, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="containts-page-section">
          <div className="container">
            <div className="text-center">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="company-name">
              <p className="effective-date">
                <strong>Effective Date:</strong> January 1, 2025
              </p>

              <p className="welcome">
                Welcome to our car service! By using our services, you agree to abide by
                the following terms and conditions. Please read these terms carefully
                before accessing or using our services. If you do not agree with these
                terms, you should not use our website or services.
              </p>

              <h2 className="acceptance-of-terms">1. Acceptance of Terms</h2>
              <p className="acceptance-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut malesuada
                odio eu felis tincidunt, vitae fermentum augue tincidunt. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                Curae; Proin eget eros urna. Aliquam vel orci ut purus feugiat
                vulputate.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndCondition;
