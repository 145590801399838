import React from "react";
// import carloader from "../ulits/images/car_loader.png";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function CustomLoader() {

  useGSAP(() => {
    gsap.from(".skeleton-container", {
      x: -10,
      opacity: 0,
      duration: 0.5,
      delay: 0.1,
      stagger: 0.2,
    });
  }, []);
  return (
    <div className="custom-loader">
      <div class="loader"></div>
      {/* <div class="skeleton-container">
        <div class="skeleton-profile">
          <div class="skeleton-circle"></div>
          <div class="skeleton-info">
            <div class="skeleton-line"></div>
            <div class="skeleton-line short"></div>
          </div>
        </div>
        <div class="skeleton-content">
          <div class="skeleton-line"></div>
          <div class="skeleton-line"></div>
          <div class="skeleton-line short"></div>
        </div>
      </div> */}
    </div>
  );
}

export default CustomLoader;
