import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, pageAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";

function AboutChooseus() {
  const [loader, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState();
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();

  const aboutChooseData = useSelector((state) => state.page);

  useEffect(() => {
    if (aboutChooseData && aboutChooseData.about_status === status.SUCCESS)
      setAboutData(aboutChooseData.about_data);
  }, [aboutChooseData.about_data]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
    dispatch(pageAction.getAboutChooseData());
  }, []);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData.brands_data]);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-list-section second choose-us">
            <div className="container">
              <div className="about-item-box">
                <div className="about-list-box">
                  <h1>{aboutData && aboutData.Title}</h1>
                  {parse(aboutData && aboutData.Description)}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${
                      aboutData && aboutData.AboutChooseImage
                    }`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
            <div className="home-choose-us-section about-choose">
              <div className="container">
                <div className="about-list-choose">
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        {/* <img src={tabimg1} alt="car-icon" loading="lazy" /> */}
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo1
                          }`}
                          alt="tab-icon"
                        />

                        {parse(aboutData && aboutData.Blog1)}
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        {/* <img src={tabimg1} alt="car-icon" loading="lazy" /> */}
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo2
                          }`}
                          alt="tab-icon"
                        />
                        {parse(aboutData && aboutData.Blog2)}
                      </div>
                    </div>
                  </div>
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        {/* <img src={tabimg1} alt="car-icon" loading="lazy" /> */}
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo3
                          }`}
                          alt="tab-icon"
                        />
                        {parse(aboutData && aboutData.Blog3)}
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-icon d-block d-md-none">
                        {/* <img src={tabimg1} alt="car-icon" loading="lazy" /> */}
                      </div>
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo4
                          }`}
                          alt="tab-icon"
                        />
                        {parse(aboutData && aboutData.Blog4)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1000}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
                className="sider-left-scroll"
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={1000}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
                className="slider-right-scroll"
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutChooseus;
