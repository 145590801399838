import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import financeimg from "../../ulits/images/finance-img.png";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { financeAction } from "../../_actions/finance.action";
import { status } from "../../_constants/commonLhd";
import { useNavigate } from "react-router";
import { configs } from "../../config";
import { configes } from "../../webData";

function Finance() {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const [isValidate, setIsValidate] = useState(false);
  const navigate = useNavigate();
  const financeData = useSelector((state) => state.finance);
  const [faqs, setFaqs] = useState([]);
  const [logos, setLogos] = useState([]);
  const [descripation, setDescripation] = useState({});
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;

  const [formData, setFormData] = useState([
    {
      name: "",
      email: "",
      phone: "",
      dob: "",
      maritualStatus: "",
      address: "",
      livingAtThatAddress: "",
      ownership: "",
      previousAddress: "",
      livingAtPriviousAddress: "",
      previousOwnership: "",
    },
    {
      company: "",
      model: "",
      purchasePrice: "",
      financeRequire: "",
      preferTerms: "",
    },
    {
      jobTitle: "",
      employerName: "",
      employmentType: "",
      employmentAddress: "",
    },
    {
      previousEmploymentjobTitle: "",
      previousEmployerName: "",
      previousEmploymentType: "",
      previousEmploymentAddress: "",
      previousEmploymentPhoneNo: "",
      previousEmploymentDuration: "",
    },
    {
      tenantId: configes.domainId,
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 600);
  }, []);

  useEffect(() => {
    if (financeData?.finance_faq_status === status.SUCCESS) {
      setFaqs(financeData.finance_faq_data);
    }
  }, [financeData.finance_faq_data]);

  useEffect(() => {
    if (financeData?.finance_Logo_status === status.SUCCESS) {
      setLogos(financeData.finance_Logo_data);
    }
  }, [financeData.finance_Logo_data]);

  useEffect(() => {
    if (financeData?.finance_Description_status === status.SUCCESS) {
      setDescripation(financeData.finance_Description_data);
    }
  }, [financeData.finance_Description_data]);

  useEffect(() => {
    dispatch(financeAction.getFaq());
    dispatch(financeAction.getFinanceLogo());
    dispatch(financeAction.getFinanceDescription());
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) {
      return;
    }
    if (name === "previousEmploymentPhoneNo" && value.length > 12) {
      return;
    }
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(financeAction.finance(formData));
      navigate("/");
    }
  };

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      dob: validObj,
      maritualStatus: validObj,
      address: validObj,
      livingAtThatAddress: validObj,
      previousAddress: validObj,
      livingAtPriviousAddress: validObj,
      ownership: validObj,
      previousOwnership: validObj,
      company: validObj,
      model: validObj,
      purchasePrice: validObj,
      financeRequire: validObj,
      preferTerms: validObj,
      jobTitle: validObj,
      employerName: validObj,
      employmentType: validObj,
      employmentAddress: validObj,
      previousEmploymentjobTitle: validObj,
      previousEmployerName: validObj,
      previousEmploymentType: validObj,
      previousEmploymentAddress: validObj,
      previousEmploymentPhoneNo: validObj,
      previousEmploymentDuration: validObj,
      isValid,
    };

    if (isValidate) {
      const {
        name,
        email,
        phone,
        dob,
        maritualStatus,
        address,
        livingAtThatAddress,
        ownership,
        previousAddress,
        livingAtPriviousAddress,
        previousOwnership,
      } = formData[0];
      const { company, model, purchasePrice, financeRequire, preferTerms } =
        formData[1];
      const { jobTitle, employerName, employmentType, employmentAddress } =
        formData[2];
      const {
        previousEmploymentjobTitle,
        previousEmployerName,
        previousEmploymentType,
        previousEmploymentAddress,
        previousEmploymentPhoneNo,
        previousEmploymentDuration,
      } = formData[3];

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (email && !emailRegex.test(email)) {
        retData.email = {
          message: "Invalid email format",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone number is required",
          isValid: false,
        };
        isValid = false;
      } else if (phone && !PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Enter a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!dob) {
        retData.dob = {
          message: "Date of birth must be in the format DD-MM-YYYY",
          isValid: false,
        };
        isValid = false;
      }

      if (!maritualStatus) {
        retData.maritualStatus = {
          message: "Maritual status is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!address) {
        retData.address = {
          message: "Address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!livingAtThatAddress) {
        retData.livingAtThatAddress = {
          message:
            "Living duration at that address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }
      if (!previousAddress) {
        retData.previousAddress = {
          message: "Pervious Address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }
      if (!livingAtPriviousAddress) {
        retData.livingAtPriviousAddress = {
          message:
            "Living duration at that address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!ownership) {
        retData.ownership = {
          message: "Ownership is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousOwnership) {
        retData.previousOwnership = {
          message: "Previous ownership is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!company) {
        retData.company = {
          message: "Company name is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!model) {
        retData.model = {
          message: "Model is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!purchasePrice) {
        retData.purchasePrice = {
          message: "Purchase price is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!financeRequire) {
        retData.financeRequire = {
          message: "Finance is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!preferTerms) {
        retData.preferTerms = {
          message: "Prefer terms is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!jobTitle) {
        retData.jobTitle = {
          message: "Job title is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!employerName) {
        retData.employerName = {
          message: "Employer name is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!employmentType) {
        retData.employmentType = {
          message: "Employment Stuatus is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!employmentAddress) {
        retData.employmentAddress = {
          message: "Employment address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmploymentjobTitle) {
        retData.previousEmploymentjobTitle = {
          message: "Previous job title is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmployerName) {
        retData.previousEmployerName = {
          message: "Previous employer name is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmploymentType) {
        retData.previousEmploymentType = {
          message: "Previous employment type is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmploymentAddress) {
        retData.previousEmploymentAddress = {
          message:
            "Previous employment address is required and should be a string.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmploymentPhoneNo) {
        retData.previousEmploymentPhoneNo = {
          message: "Previous employment phone number is required.",
          isValid: false,
        };
        isValid = false;
      }

      if (!previousEmploymentDuration) {
        retData.previousEmploymentDuration = {
          message: "Previous employment duration is required.",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const error = validateCredentials(isValidate);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="finance-section">
            <div className="container">
              <div className="title d-block d-md-none">
                <h2>FINANCE</h2>
              </div>
              {descripation &&
                descripation.map((descripation, index) => (
                  <div key={index}>
                    <div className="finance-img">
                      <img
                        src={`${configs.localApiUrl}${descripation.image}`}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <div className="finance-title">
                      <h1>{descripation.key}</h1>
                      <p>{descripation.value}</p>
                    </div>
                  </div>
                ))}

              <div className="finance-list">
                <h1>APPLY FOR FINANCE</h1>
                <h3>PERSONAL DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="NAME"
                            className="form-control"
                            value={formData[0].name}
                            onChange={(e) => handleChange(e, 0)}
                          />
                          {error && error.name.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.name.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder="PHONE"
                            className="form-control"
                            value={formData[0].phone}
                            onChange={(e) => handleChange(e, 0)}
                          />
                          {error && error.phone.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.phone.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="maritualStatus"
                            name="maritualStatus"
                            value={formData[0].maritualStatus}
                            onChange={(e) => handleChange(e, 0)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="" selected>
                              MARITIAL STATUS
                            </option>
                            <option value="MARRIED">Married</option>
                            <option value="SINGLE">Single</option>
                            <option value="OTHER">Other</option>
                          </select>
                          {error && error.maritualStatus.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.maritualStatus.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="livingAtThatAddress"
                            name="livingAtThatAddress"
                            value={formData[0].livingAtThatAddress}
                            onChange={(e) => handleChange(e, 0)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="">TIME AT ADDRESS</option>
                            <option value="Less Than 1 Year">
                              Less Than 1 Year
                            </option>
                            <option value="2 Year">2 Year</option>
                            <option value="3 Year">3 Year</option>
                            <option value="4 Year">4 Year</option>
                            <option value="5 Year">5 Year</option>
                            <option value="6 Year">+6 Year</option>
                          </select>
                          {error &&
                            error.livingAtThatAddress.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.livingAtThatAddress.message}
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <input
                            id="previousAddress"
                            name="previousAddress"
                            value={formData[0].previousAddress}
                            onChange={(e) => handleChange(e, 0)}
                            type="text"
                            placeholder="PREVIOUS ADDRESS"
                            className="form-control"
                          />
                          {error && error.previousAddress.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.previousAddress.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="previousOwnership"
                            name="previousOwnership"
                            value={formData[0].previousOwnership}
                            onChange={(e) => handleChange(e, 0)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="OWNERSHIP" selected>
                              OWNERSHIP
                            </option>
                            <option value="Owner">Owner</option>
                            <option value="tenant">Tenant</option>
                            <option value="Parents">Parents</option>
                          </select>
                          {error && error.previousOwnership.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.previousOwnership.message}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            value={formData[0].email}
                            onChange={(e) => handleChange(e, 0)}
                            type="text"
                            placeholder="EMAIL"
                            className="form-control"
                          />
                          {error && error.email.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.email.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="dob"
                            name="dob"
                            value={formData[0].dob}
                            onChange={(e) => handleChange(e, 0)}
                            type="text"
                            placeholder="DOB"
                            className="form-control"
                          />
                          {error && error.dob.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.dob.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="address"
                            name="address"
                            value={formData[0].address}
                            onChange={(e) => handleChange(e, 0)}
                            type="text"
                            placeholder="ADDRESS"
                            className="form-control"
                          />
                          {error && error.address.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.address.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="ownership"
                            name="ownership"
                            value={formData[0].ownership}
                            onChange={(e) => handleChange(e, 0)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="OWNERSHIP" selected>
                              OWNERSHIP
                            </option>
                            <option value="Tenant">Tenant</option>
                            <option value="Owner">Owner</option>
                            <option value="Parents">Parents</option>
                          </select>
                          {error && error.ownership.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.ownership.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="livingAtPriviousAddress"
                            name="livingAtPriviousAddress"
                            value={formData[0].livingAtPriviousAddress}
                            onChange={(e) => handleChange(e, 0)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                              marginTop: "0px",
                            }}
                          >
                            <option value="" selected>
                              TIME AT PREVIOUS ADDRESS
                            </option>
                            <option value="Less Than 1 Year">
                              Less Than 1 Year
                            </option>
                            <option value="2 Year">2 Year</option>
                            <option value="3 Year">3 Year</option>
                            <option value="4 Year">4 Year</option>
                            <option value="5 Year">5 Year</option>
                            <option value="6 Year">+6 Year</option>
                          </select>
                          {error &&
                            error.livingAtPriviousAddress.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.livingAtPriviousAddress.message}
                              </div>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <h3>VECHICLE DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="company"
                            name="company"
                            value={formData[1].company}
                            onChange={(e) => handleChange(e, 1)}
                            type="text"
                            placeholder="MAKE"
                            className="form-control"
                          />
                          {error && error.company.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.company.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="purchasePrice"
                            name="purchasePrice"
                            value={formData[1].purchasePrice}
                            onChange={(e) => handleChange(e, 1)}
                            type="text"
                            placeholder="PURCHASE PRICE"
                            className="form-control"
                          />
                          {error && error.purchasePrice.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.purchasePrice.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="preferTerms"
                            name="preferTerms"
                            value={formData[1].preferTerms}
                            onChange={(e) => handleChange(e, 1)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                              textTransform: "uppercase",
                            }}
                          >
                            <option value="">Perfer Terms</option>
                            <option value="12 months">12 months</option>
                            <option value="24 months">24 months</option>
                            <option value="36 months">36 months</option>
                            <option value="48 months">48 months</option>
                            <option value="60 months">60 months</option>
                          </select>
                          {error && error.preferTerms.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.preferTerms.message}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="model"
                            name="model"
                            value={formData[1].model}
                            onChange={(e) => handleChange(e, 1)}
                            type="text"
                            placeholder="MODEL"
                            className="form-control"
                          />
                          {error && error.model.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.model.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="financeRequire"
                            name="financeRequire"
                            value={formData[1].financeRequire}
                            onChange={(e) => handleChange(e, 1)}
                            type="text"
                            placeholder="FINANCE REQUIRED"
                            className="form-control"
                          />
                          {error && error.financeRequire.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.financeRequire.message}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <h3>EMPLOYMENT DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="jobTitle"
                            name="jobTitle"
                            value={formData[2].jobTitle}
                            onChange={(e) => handleChange(e, 2)}
                            type="text"
                            placeholder="JOB TITLE"
                            className="form-control"
                          />
                          {error && error.jobTitle.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.jobTitle.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <select
                            id="EMPLOYMENT STATUS"
                            name="employmentType"
                            value={formData[2].employmentType}
                            onChange={(e) => handleChange(e, 2)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="">EMPLOYMENT STATUS</option>
                            <option value="Self Employed">Self Employed</option>
                            <option value="Employed">Employed</option>
                          </select>
                          {error && error.employmentType.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.employmentType.message}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="employerName"
                            name="employerName"
                            value={formData[2].employerName}
                            onChange={(e) => handleChange(e, 2)}
                            type="text"
                            placeholder="EMPLOYERS NAME"
                            className="form-control"
                          />
                          {error && error.employerName.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.employerName.message}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <input
                            id="employmentAddress"
                            name="employmentAddress"
                            value={formData[2].employmentAddress}
                            onChange={(e) => handleChange(e, 2)}
                            type="text"
                            placeholder="EMPLOYERS ADDRESS"
                            className="form-control"
                          />
                          {error && error.employmentAddress.message !== "" && (
                            <div
                              className="error-message"
                              style={{ color: "red" }}
                            >
                              {error.employmentAddress.message}
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <h3>PREVIOUS EMPLOYMENT DETAILS</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="previousEmploymentjobTitle"
                            name="previousEmploymentjobTitle"
                            value={formData[3].previousEmploymentjobTitle}
                            onChange={(e) => handleChange(e, 3)}
                            type="text"
                            placeholder="JOB TITLE"
                            className="form-control"
                          />
                          {error &&
                            error.previousEmploymentjobTitle.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.previousEmploymentjobTitle.message}
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <input
                            id="previousEmployerName"
                            name="previousEmployerName"
                            value={formData[3].previousEmployerName}
                            onChange={(e) => handleChange(e, 3)}
                            type="text"
                            placeholder="EMPLOYERS NAME"
                            className="form-control"
                          />
                          {error &&
                            error.previousEmployerName.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.previousEmployerName.message}
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <select
                            id="previousEmploymentType"
                            name="previousEmploymentType"
                            value={formData[3].previousEmploymentType}
                            onChange={(e) => handleChange(e, 3)}
                            style={{
                              padding: "10px",
                              border: "1px solid #77868d",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "0px",
                              height: "50px",
                              color: "#77868d",
                              fontFamily: "DIN_2014",
                              fontSize: "12px",
                              outline: "none",
                            }}
                          >
                            <option value="">EMPLOYMENT STATUS</option>
                            <option value="Self Employed">Self Employed</option>
                            <option value="Employed">Employed</option>
                          </select>
                          {error &&
                            error.previousEmploymentType.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.previousEmploymentType.message}
                              </div>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12">
                    <div
                      className="form-group"
                      style={{ marginBottom: "20px" }}
                    >
                      <select
                        id="previousEmploymentDuration"
                        name="previousEmploymentDuration"
                        value={formData[3].previousEmploymentDuration}
                        onChange={(e) => handleChange(e, 3)}
                        style={{
                          padding: "10px",
                          border: "1px solid #77868d",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "0px",
                          height: "50px",
                          color: "#77868d",
                          fontFamily: "DIN_2014",
                          fontSize: "12px",
                          outline: "none",
                          textTransform: "uppercase",
                        }}
                      >
                        <option value="">Length of Employment</option>
                        <option value="Less Than 1 Year">
                          Less Than 1 Year
                        </option>
                        <option value="2 Year">2 Year</option>
                        <option value="3 Year">3 Year</option>
                        <option value="4 Year">4 Year</option>
                        <option value="5 Year">5 Year</option>
                        <option value="6 Year">+6 Year</option>
                      </select>
                      {error &&
                        error.previousEmploymentDuration.message !== "" && (
                          <div
                            className="error-message"
                            style={{ color: "red" }}
                          >
                            {error.previousEmploymentDuration.message}
                          </div>
                        )}
                    </div>
                    <div className="details-form">
                      <form>
                        <div className="form-group">
                          <input
                            id="previousEmploymentAddress"
                            name="previousEmploymentAddress"
                            value={formData[3].previousEmploymentAddress}
                            onChange={(e) => handleChange(e, 3)}
                            type="text"
                            placeholder="EMPLOYERS ADDRESS"
                            className="form-control"
                          />
                          {error &&
                            error.previousEmploymentAddress.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.previousEmploymentAddress.message}
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <input
                            id="previousEmploymentPhoneNo"
                            name="previousEmploymentPhoneNo"
                            value={formData[3].previousEmploymentPhoneNo}
                            onChange={(e) => handleChange(e, 3)}
                            type="text"
                            placeholder="EMPLOYERS PHONE NUMBER"
                            className="form-control"
                          />
                          {error &&
                            error.previousEmploymentPhoneNo.message !== "" && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.previousEmploymentPhoneNo.message}
                              </div>
                            )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn primary-btn"
                  onClick={handleSubmit}
                >
                  SEND MESSAGE
                </button>
              </div>
            </div>
            <div className="stm-accordion-single-unit right-hand-drive">
              <div className="container">
                <div className="stm-accordion-title">
                  <h3>FREQUENTLY ASKED QUESTIONS</h3>
                </div>
                <Accordion defaultActiveKey="0">
                  {faqs &&
                    faqs.length > 0 &&
                    faqs.map((item, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{item.que}</Accordion.Header>
                        <Accordion.Body>{item.ans}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            </div>
            <div className="wpb_wrapper">
              <div className="container">
                <ul>
                  <li>
                    <img
                      src={`${configs.localApiUrl}${logos[4]?.image}`}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={`${configs.localApiUrl}${logos[0]?.image}`}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={`${configs.localApiUrl}${logos[1]?.image}`}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={`${configs.localApiUrl}${logos[2]?.image}`}
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src={`${configs.localApiUrl}${logos[3]?.image}`}
                      alt=""
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Finance;
