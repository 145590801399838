import { status } from "../_constants/commonLhd";
import { financeConstant } from "../_constants/finance.constant";

let Initialstate = {};
console.log(5, Initialstate);

export function finance(state = Initialstate, action) {
  switch (action.type) {
    case financeConstant.GET_FINANCE_REQUEST:
      return {
        ...state,
        finance_status: status.IN_PROGRESS,
        finance_data: null,
      };
    case financeConstant.GET_FINANCE_SUCCESS:
      return {
        ...state,
        finance_status: status.SUCCESS,
        finance_data: action.data,
      };
    case financeConstant.GET_FINANCE_FAILURE:
      return {
        ...state,
        finance_status: status.FAILURE,
        finance_data: action.data,
      };
    case financeConstant.GET_fAQ_REQUEST:
      return {
        ...state,
        finance_faq_status: status.IN_PROGRESS,
        finance_faq_data: null,
      };
    case financeConstant.GET_fAQ_SUCCESS:
      return {
        ...state,
        finance_faq_status: status.SUCCESS,
        finance_faq_data: action.data,
      };
    case financeConstant.GET_fAQ_FAILURE:
      return {
        ...state,
        finance_faq_status: status.FAILURE,
        finance_faq_data: action.data,
      };
    case financeConstant.GET_FINANCELOGO_REQUEST:
      return {
        ...state,
        finance_Logo_status: status.IN_PROGRESS,
        finance_Logo_data: null,
      };
    case financeConstant.GET_FINANCELOGO_SUCCESS:
      return {
        ...state,
        finance_Logo_status: status.SUCCESS,
        finance_Logo_data: action.data,
      };
    case financeConstant.GET_FINANCELOGO_FAILURE:
      return {
        ...state,
        finance_Logo_status: status.FAILURE,
        finance_Logo_data: action.data,
      };
    case financeConstant.GET_FINANCEDESCRIPTION_REQUEST:
      return {
        ...state,
        finance_Description_status: status.IN_PROGRESS,
        finance_Description_data: null,
      };
    case financeConstant.GET_FINANCEDESCRIPTION_SUCCESS:
      return {
        ...state,
        finance_Description_status: status.SUCCESS,
        finance_Description_data: action.data,
      };
    case financeConstant.GET_FINANCEDESCRIPTION_FAILURE:
      return {
        ...state,
        finance_Description_status: status.FAILURE,
        finance_Description_data: action.data,
      };
    default:
      return state;
  }
}
