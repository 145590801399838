import { HpiCheckConstant } from "../_constants/HPICheck.constant";
import { HPICheckService } from "../_services/HPICheck.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";

export const HPICheckAction = {
  createFrench,
  createSpanish,
  getFrenchDescription,
  getSpanishDescription,
};

function createFrench(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: HpiCheckConstant.FRENCH_HPI_CHECK_REQUEST,
        data: null,
      })
    );

    HPICheckService.createFrench({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.FRENCH_HPI_CHECK_SUCCESS,
                data: response.Result.french,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.FRENCH_HPI_CHECK_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: HpiCheckConstant.FRENCH_HPI_CHECK_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function createSpanish(data) {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: HpiCheckConstant.SPANISH_HPI_CHECK_REQUEST,
        data: null,
      })
    );

    HPICheckService.createSpanish({ ...data, tenantId: configes.domainId })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.SPANISH_HPI_CHECK_SUCCESS,
                data: response.Result.spanish,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.SPANISH_HPI_CHECK_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: HpiCheckConstant.SPANISH_HPI_CHECK_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function getFrenchDescription() {
  console.log("106", getFrenchDescription);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: HpiCheckConstant.GET_FRENCHDESCRIPTION_REQUEST,
        data: null,
      })
    );
    HPICheckService.getFrenchDescription()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.GET_FRENCHDESCRIPTION_SUCCESS,
                data: response.Result.Description,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.GET_FRENCHDESCRIPTION_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: HpiCheckConstant.GET_FRENCHDESCRIPTION_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function getSpanishDescription() {
  console.log("106", getSpanishDescription);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: HpiCheckConstant.GET_SPANISHDESCRIPTION_REQUEST,
        data: null,
      })
    );
    HPICheckService.getSpanishDescription()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.GET_SPANISHDESCRIPTION_SUCCESS,
                data: response.Result.Description,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: HpiCheckConstant.GET_SPANISHDESCRIPTION_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: HpiCheckConstant.GET_SPANISHDESCRIPTION_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!");
        }
      )
      .catch((error) => {
        alert.error("Oops! Something went wrong!");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
