import { contactConstant } from "../_constants/contact.constants";
import { status } from "../_constants/commonLhd";

let initialState = {};
console.log(initialState);

export function contact(state = initialState, action) {
  // console.log("Contact Reducer", action);
  switch (action.type) {
    case contactConstant.CONTACT_REQUEST:
      return {
        ...state,
        contact_status: status.IN_PROGRESS,
        contact_data: null,
      };
    case contactConstant.CONTACT_SUCCESS:
      // console.log("Contact Reducer", action.data);
      return {
        ...state,
        contact_status: status.SUCCESS,
        contact_data: action.data,
      };
    case contactConstant.CONTACT_FAILURE:
      // console.log("Contact Reducer", action.data);
      return {
        ...state,
        contact_status: status.FAILURE,
        contact_data: action.data,
      };

    case contactConstant.OFFICE_REQUEST:
      return {
        ...state,
        office_status: status.IN_PROGRESS,
        office_data: null,
      };
    case contactConstant.OFFICE_SUCCESS:
      // console.log("Contact Reducer", action.data);
      return {
        ...state,
        office_status: status.SUCCESS,
        office_data: action.data,
      };
    case contactConstant.OFFICE_FAILURE:
      // console.log("Contact Reducer", action.data);
      return {
        ...state,
        office_status: status.FAILURE,
        office_data: action.data,
      };
    default:
      return state;
  }
}
