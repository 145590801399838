import { status } from "../_constants/commonLhd";
import { pageConstant } from "../_constants/page.constant";

let initialState = {};

export function page(state = initialState, action) {
  switch (action.type) {
    case pageConstant.PAGE_REQUEST:
      return {
        ...state,
        page_status: status.IN_PROGRESS,
        page_data: null,
      };
    case pageConstant.PAGE_SUCCESS:
      return {
        ...state,
        page_status: status.SUCCESS,
        page_data: action.data,
      };
    case pageConstant.PAGE_FAILURE:
      return {
        ...state,
        page_status: status.FAILURE,
        page_data: action.data,
      };
    case pageConstant.ABOUTCHOOSE_DATA_REQUEST:
      return {
        ...state,
        about_status: status.IN_PROGRESS,
        about_data: null,
      };
    case pageConstant.ABOUTCHOOSE_DATA_SUCCESS:
      return {
        ...state,
        about_status: status.SUCCESS,
        about_data: action.data,
      };
    case pageConstant.ABOUTCHOOSE_DATA_FAILURE:
      return {
        ...state,
        about_status: status.FAILURE,
        about_data: action.data,
      };

    default:
      return state;
  }
}
