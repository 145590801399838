import { configs } from "../config";
import { commonFunctions } from "../_utilities/commonFunctions";
import { configes } from "../webData";

export const pageService = {
  getPages,
  getAboutChooseData,
};

function getPages(query = "?section=about&section=about-history") {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getPage/${configes.domainId}${query}`,
    requestOptions
  ).then((response) => response.json());
}

function getAboutChooseData(query = "?section=about-choose") {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getAboutData/${configes.domainId}${query}`,
    requestOptions
  ).then((response) => response.json());
}
