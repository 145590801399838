import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/CustomeLoader";
import milionicon from "../../ulits/images/milion_icon.svg";
import petrolicon from "../../ulits/images/petrol_icon.svg";
import manualicon from "../../ulits/images/manual_icon.svg";
import googlepay from "../../ulits/images/google-pay.svg";
import staricon from "../../ulits/images/star-img.svg";
import carimg3 from "../../ulits/images/car_img3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { homeAction, pageAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";
import { configs } from "../../config";
import whatsapp from "../../ulits/images/WatSapp-icon.svg";
import parse from "html-react-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Home() {
  const [loader, setLoading] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const [cars, setCars] = useState([]);
  const [brands, setBrands] = useState([]);
  const [aboutData, setAboutData] = useState();
  const [topBanner, setTopBanner] = useState({});
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
    dispatch(homeAction.getCarsByTenantId());
    dispatch(homeAction.getTopBannerDetail());
  }, [dispatch]);

  useEffect(() => {
    if (homeData && homeData.car_status === status.SUCCESS) {
      setCars(homeData.car_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.top_banner_status === status.SUCCESS) {
      setTopBanner(homeData.top_banner_data);
    }
  }, [homeData]);

  useEffect(() => {
    if (homeData && homeData.email_status === status.SUCCESS) {
    }
  }, [homeData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      email: validObj,
      isValid,
    };

    if (isValidate) {
      const { email } = credentials;

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Please enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateCredentials(true);
    setIsValidate(true);
    if (error.isValid) {
      dispatch(homeAction.sendEmail(credentials));
      setCredentials({ email: "" });
      setIsValidate(false)
      navigate("/");
    }
  };

  const handleButtonClick = (buttonLink) => {
    if (buttonLink.startsWith("http")) {
      window.open(buttonLink, "_blank");
    } else {
      navigate(buttonLink);
    }
  };

  const aboutChooseData = useSelector((state) => state.page);

  useEffect(() => {
    if (aboutChooseData && aboutChooseData.about_status === status.SUCCESS)
      setAboutData(aboutChooseData.about_data);
  }, [aboutChooseData]);

  useEffect(() => {
    dispatch(pageAction.getAboutChooseData());
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    // pauseOnHover: true,
  };
  const setting = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    // cssEase: "ease",
    // rtl: false,
    rtl: true,
  };

  const error = validateCredentials(isValidate);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="home-col-section first">
            <div className="col-text blue-bg order-last">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {topBanner &&
                  topBanner.length > 0 &&
                  topBanner.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className="our-courses-box">
                        <div className="date-inner-title">
                          <div className="date-title">
                            <h2>{item.title}</h2>
                            {parse(item.description)}
                            <button
                              className="primary-btn sm"
                              onClick={() => handleButtonClick(item.buttonLink)}
                            >
                              {item.buttonText}
                              <div className="arrow-icon"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${config?.topBannerImage}`}
                alt="home-col-1"
              />
              <div className="WatSapp-icon">
                <div className="whatsapp-icon">
                  <Link
                    to={`https://wa.me/${config && config?.Contact_Number}`}
                  >
                    <img src={whatsapp} alt="whatsapp" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="home-new-section second">
            <div className="col-image">
              <div className="container">
                <div className="date-inner-title containe">
                  <div className="date-title">
                    <h1>NEW IN</h1>
                  </div>
                  <div className="box-wrapper">
                    {cars &&
                      cars.slice(0, 6).map((cars, index) => (
                        <div
                          className="box"
                          key={index}
                          onClick={() =>
                            navigate(`/car-details/${cars.slug}`, {
                              state: cars,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${configs.localApiUrl}${cars.image[0]}`}
                            alt="home-col-1"
                            style={{ cursor: "pointer" }}
                          />
                          <div className="box-content-inner">
                            <div className="box-content">
                              <h1>{cars && cars.details.toUpperCase()}</h1>
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={milionicon} alt="arrow-icon" />
                                    {cars.mileage} MILES
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={petrolicon} alt="arrow-icon" />
                                    {cars && cars.fuelType.toUpperCase()}
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={manualicon} alt="arrow-icon" />
                                    {cars && cars.transmission.toUpperCase()}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <button className="primary-btn sm">
                              £{cars.price}
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                  <button
                    className="primary-btn-list"
                    onClick={() => navigate("/car-listing")}
                  >
                    FULL STOCK LIST
                  </button>
                </div>
              </div>
            </div>
            <div className="home-choose-us-section">
              <div className="container">
                <div className="heading-title">
                  <h1>{aboutData && aboutData.Title}</h1>
                </div>
                <div className="choose-us-box">
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo1) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {/* {aboutData && aboutData.Blog1 ? 
                        parse(aboutData && aboutData.Blog1)
                        : null} */}
                        {aboutData && typeof aboutData.Blog1 === "string"
                          ? parse(aboutData && aboutData.Blog1)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.BlogImage1) || ""
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.Logo2
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Blog2 === "string"
                          ? parse(aboutData && aboutData.Blog2)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.BlogImage2
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="choose-us-wrapper">
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo3) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Blog3 === "string"
                          ? parse(aboutData && aboutData.Blog3)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.BlogImage3
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                    <div className="choose-us-box">
                      <div className="choose-us-content">
                        <img
                          src={`${configs.localApiUrl}${
                            (aboutData && aboutData.Logo4) || ""
                          }`}
                          alt="tab-icon"
                        />
                        {aboutData && typeof aboutData.Logo4 === "string"
                          ? parse(aboutData && aboutData.Blog4)
                          : null}
                      </div>
                      <div className="choose-us-icon">
                        <img
                          src={`${configs.localApiUrl}${
                            aboutData && aboutData.BlogImage4
                          }`}
                          alt="car-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="google-review-section">
              <div className="container">
                <div className="heading-title">
                  <h1>GOOGLE REVIEWS</h1>
                </div>
                <div className="google-review-wrapper-inner first-box">
                  <div className="google-content-inner">
                    <img
                      src={googlepay}
                      alt="google-img"
                      className="google-img"
                    />
                    <p>5 stars</p>
                    <img
                      src={staricon}
                      alt="arrow-icon"
                      className="star-icon"
                    />
                    <p>234 reviews</p>
                  </div>
                </div>
                <div className="google-review-wrapper-box">
                  <div className="google-review-wrapper">
                    <div className="google-review-box">
                      <div className="google-review-img">
                        <img
                          src={staricon}
                          alt="arrow-icon"
                          className="google-img"
                        />
                        <img
                          src={googlepay}
                          alt="google-img"
                          className="star-icon"
                        />
                      </div>
                      <div className="google-review-content">
                        <h2>1 month ago</h2>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Justo blandit sit nunc; curae enim lectus! Elit mi
                          auctor duis ligula magnis. Congue lectus rhoncus odio
                          venenatis sem. Quam sollicitudin potenti turpis
                          torquent semper.
                        </p>
                        <h2>John Smith</h2>
                      </div>
                    </div>
                    <div className="google-review-box">
                      <div className="google-review-img">
                        <img
                          src={staricon}
                          alt="arrow-icon"
                          className="google-img"
                        />
                        <img
                          src={googlepay}
                          alt="google-img"
                          className="star-icon"
                        />
                      </div>
                      <div className="google-review-content">
                        <h2>1 month ago</h2>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Justo blandit sit nunc; curae enim lectus! Elit mi
                          auctor duis ligula magnis. Congue lectus rhoncus odio
                          venenatis sem. Quam sollicitudin potenti turpis
                          torquent semper.
                        </p>
                        <h2>John Smith</h2>
                      </div>
                    </div>
                    <div className="google-review-box">
                      <div className="google-review-img">
                        <img
                          src={staricon}
                          alt="arrow-icon"
                          className="google-img"
                        />
                        <img
                          src={googlepay}
                          alt="google-img"
                          className="star-icon"
                        />
                      </div>
                      <div className="google-review-content">
                        <h2>1 month ago</h2>
                        <p>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Justo blandit sit nunc; curae enim lectus! Elit mi
                          auctor duis ligula magnis. Congue lectus rhoncus odio
                          venenatis sem. Quam sollicitudin potenti turpis
                          torquent semper.
                        </p>
                        <h2>John Smith</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="join-our-section">
              <div className="join-title">
                <div className="container join-title-inner">
                  <h1>JOIN OUR NEWSLETTER NOW</h1>
                  <form className="join-form" onSubmit={handleSubmit}>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={credentials.email}
                      onChange={handleChange}
                      placeholder="Enter Your Email Address"
                      className="form-control"
                      required
                    />
                    <div
                      type="submit"
                      className="arrow-icons"
                      onClick={handleSubmit}
                    ></div>
                  </form>
                  {error && error.email.message !== "" && (
                    <div className="error-message" style={{ color: "red" }}>
                      {error.email.message}
                    </div>
                  )}
                </div>
                <div className="join-img">
                  <img
                    src={`${configs.localApiUrl}${config?.joinImage}`}
                    alt="join-img"
                  />
                </div>
              </div>
            </div>
            <div className="follow-us-section">
              <div className="col-image">
                <img src={carimg3} alt="follow-img" />
              </div>
              <div className="col-content">
                <h2>FOLLOW US ON SOCIALS</h2>
                <ul>
                  <li>
                    <Link
                      to={
                        config && config?.Tiktok_Url ? config?.Tiktok_Url : "#"
                      }
                    >
                      <img
                        src={`${configs.localApiUrl}${config?.tiktokLogo}`}
                        alt="tiktok_icon"
                        onClick={() => window.open(config?.Tiktok_Url)}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Snapchat_Url
                          ? config?.Snapchat_Url
                          : "#"
                      }
                    >
                      <img
                        src={`${configs.localApiUrl}${config?.snapchatLogo}`}
                        alt="snapchat_icon"
                        onClick={() => window.open(config?.Snapchat_Url)}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Instagram_Url
                          ? config?.Instagram_Url
                          : "#"
                      }
                    >
                      <img
                        src={`${configs.localApiUrl}${config?.instagramLogo}`}
                        alt="instagram_icon"
                        onClick={() => window.open(config?.Instagram_Url)}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        config && config?.Facebook_Url
                          ? config?.Facebook_Url
                          : "#"
                      }
                    >
                      <img
                        src={`${configs.localApiUrl}${config?.fecebookLogo}`}
                        alt="facebook_icon"
                        onClick={() => window.open(config?.Facebook_Url)}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="brands-stock text-center">
              <div className="head-title">
                <h2>BRANDS WE WORK WITH</h2>
                <Swiper
                  paceBetween={50}
                  slidesPerView={7.5}
                  speed={400}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 2.2,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 4.5,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 5.5,
                      spaceBetween: 20,
                    },
                    800: {
                      slidesPerView: 7.4,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 7.5,
                      spaceBetween: 20,
                    },
                  }}
                  className="sider-left-scroll d-none d-lg-block"
                >
                  {brands.map((brands) => (
                    <SwiperSlide>
                      <img
                        src={`${configs.localApiUrl}${brands.brandImage}`}
                        alt="Slide 1"
                        key={brands.id}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Swiper
                  paceBetween={50}
                  slidesPerView={7.5}
                  speed={400}
                  pagination={{ clickable: true }}
                  // scrollbar={{ draggable: true }}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    reverseDirection: true,
                  }}
                  modules={[Autoplay]}
                  loop={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 2.2,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 4.5,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 5.5,
                      spaceBetween: 20,
                    },
                    800: {
                      slidesPerView: 7.4,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 7.5,
                      spaceBetween: 20,
                    },
                  }}
                  className="slider-right-scroll d-none d-lg-block"
                >
                  {brands.map((brands) => (
                    <SwiperSlide>
                      <img
                        src={`${configs.localApiUrl}${brands.brandImage}`}
                        alt="Slide 1"
                        key={brands.id}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="slider-container d-block d-lg-none">
                  <Slider {...settings}>
                    {brands.map((brands) => (
                      <img
                        src={`${configs.localApiUrl}${brands.brandImage}`}
                        alt="Slide 1"
                        key={brands.id}
                      />
                    ))}
                  </Slider>
                </div>
                <div className="slider-container d-block d-lg-none">
                  <Slider {...setting}>
                    {brands.map((brands) => (
                      <img
                        src={`${configs.localApiUrl}${brands.brandImage}`}
                        alt="Slide 1"
                        key={brands.id}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Home;
