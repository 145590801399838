import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const contactService = {
  contact,
  office,
};

// console.log("contact service", contact);

function contact(data) {
  // console.log(10, data);
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.postRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/contactUs?tenantId=${configes.domainId}`,
    requestOptions
  ).then((responce) => responce.json());
}

function office(
  query = "?section=ukOffice&section=franceOffice&section=spanishOffice"
) {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getOffice/${configes.domainId}${query}`,
    requestOptions
  ).then((response) => response.json());
}


