import { commonFunctions } from "../_utilities/commonFunctions";
import { configs } from "../config";
import { configes } from "../webData";

export const homeService = {
  getCarsByTenantId,
  getBrandsByTenantId,
  carInquiry,
  sendEmail,
  testDrive,
  getTopBannerDetail,
  getCarDetail,
};

function getCarsByTenantId() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getCars/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getBrandsByTenantId() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getBrands/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function carInquiry(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/createCarInquirie?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function sendEmail(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/sendEmail?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function testDrive(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/testDrive?tenantId=${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getTopBannerDetail() {
  const requestOptions = commonFunctions.getRequestOptions("GET");
  return fetch(
    `${configs.localApiUrl}/getTopBannerDetail/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}

function getCarDetail(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${configs.localApiUrl}/getCarsBySlug/${configes.domainId}`,
    requestOptions
  ).then((response) => response.json());
}
