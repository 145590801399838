export const HpiCheckConstant = {
  FRENCH_HPI_CHECK_REQUEST: "FRENCH_HPI_CHECK_REQUEST",
  FRENCH_HPI_CHECK_SUCCESS: "FRENCH_HPI_CHECK_SUCCESS",
  FRENCH_HPI_CHECK_FAILURE: "FRENCH_HPI_CHECK_FAILURE",

  SPANISH_HPI_CHECK_REQUEST: "SPANISH_HPI_CHECK_REQUEST",
  SPANISH_HPI_CHECK_SUCCESS: "SPANISH_HPI_CHECK_SUCCESS",
  SPANISH_HPI_CHECK_FAILURE: "SPANISH_HPI_CHECK_FAILURE",

  GET_FRENCHDESCRIPTION_REQUEST: "GET_FRENCHDESCRIPTION_REQUEST",
  GET_FRENCHDESCRIPTION_SUCCESS: "GET_FRENCHDESCRIPTION_SUCCESS",
  GET_FRENCHDESCRIPTION_FAILURE: "GET_FRENCHDESCRIPTION_FAILURE",

  GET_SPANISHDESCRIPTION_REQUEST: "GET_SPANISHDESCRIPTION_REQUEST",
  GET_SPANISHDESCRIPTION_SUCCESS: "GET_SPANISHDESCRIPTION_SUCCESS",
  GET_SPANISHDESCRIPTION_FAILURE: "GET_SPANISHDESCRIPTION_FAILURE",
};
