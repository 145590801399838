import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import insuranceimg from "../../ulits/images/insurance-img.png";
import { status } from "../../_constants/commonLhd";
import { europeanAction } from "../../_actions/european.action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
import { configs } from "../../config";

function EuropeanInsurance() {
  const [loader, setLoader] = useState(true);
  const [description, setDescription] = useState({});
  const config = localStorage.getItem("config")
    ? JSON.parse(localStorage.getItem("config"))
    : null;
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isValidate, setIsValidate] = useState(false);
  const europeanData = useSelector((state) => state.european);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 12) {
      return;
    }
    setCredentials((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(europeanAction.getInsuranceDescription());
  }, []);
  useEffect(() => {
    if (
      europeanData &&
      europeanData.insuranceDescription_status === status.SUCCESS
    ) {
      setDescription(europeanData.insuranceDescription_data);
    }
  }, [europeanData.insuranceDescription_data]);

  const validateCredentials = (isValidate) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PhoneRegex = /^\d{10,12}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      message: validObj,
      isValid,
    };

    if (isValidate) {
      const { name, email, phone, message } = credentials;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      } else if (name && name.length <= 2) {
        retData.name = {
          message: "Name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone Number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!PhoneRegex.test(phone)) {
        retData.phone = {
          message: "Enter  a valid phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!message) {
        retData.message = {
          message: "Message is required",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const error = validateCredentials(true);
    if (error.isValid) {
      dispatch(europeanAction.european(credentials));
      navigate("/");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  const error = validateCredentials(isValidate);
  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="insurance-section">
            <div className="container">
              {(description || []).map((description, index) => (
                <div className="finance-img">
                  <div className="finance-images">
                    <img src={`${configs.localApiUrl}${description.image}`} />
                    <div className="finance-title">
                      <h1>EUROPEAN INSURANCE</h1>
                    </div>
                  </div>
                  <div
                    key={index}
                    className={`insurance-content ${
                      index % 2 === 0 ? "even-row" : "odd-row"
                    }`}
                  >
                    {parse(description.content)}
                  </div>
                </div>
              ))}
            </div>
            <div className="european-insurance-form">
              <div className="container">
                <h2>EUROPEAN INSURANCE</h2>

                <div className="insurance-group">
                  <div className="form-group">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={credentials.name}
                      onChange={handleChange}
                      placeholder="NAME"
                      required
                      className="form-control"
                    />
                    {error && error.name.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.name.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={credentials.email}
                      onChange={handleChange}
                      placeholder="EMAIL"
                      required
                      className="form-control"
                    />
                    {error && error.email.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.email.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={credentials.phone}
                      onChange={handleChange}
                      placeholder="PHONE"
                      required
                      className="form-control"
                    />
                    {error && error.phone.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.phone.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group textarea">
                    <textarea
                      id="message"
                      name="message"
                      value={credentials.message}
                      onChange={handleChange}
                      placeholder="MESSAGE"
                      required
                    />
                    {error && error.message.message !== "" && (
                      <div className="error-message" style={{ color: "red" }}>
                        {error.message.message}
                      </div>
                    )}
                  </div>
                  <button
                    className="primary-btn sm"
                    type="SEND MESSAGE"
                    title="SEND MESSAGE"
                    onClick={handleSubmit}
                  >
                    SEND MESSAGE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EuropeanInsurance;
