import { financeConstant } from "../_constants/finance.constant";
import { financeService } from "../_services/finance.service";
import { alert } from "../_utilities/alert";
import { configes } from "../webData";

export const financeAction = {
  finance,
  getFaq,
  getFinanceLogo,
  getFinanceDescription,
};

function finance(data) {
  console.log(11, data);

  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: financeConstant.GET_FINANCE_REQUEST,
        data: null,
      })
    );
    financeService
      .finance({ ...data })
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCE_SUCCESS,
                data: response,
              })
            );
            alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCE_FAILURE,
                data: null,
              })
            );
            alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: financeConstant.GET_FINANCE_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getFaq() {
  // console.log(60, getFaq);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: financeConstant.GET_fAQ_REQUEST,
        data: null,
      })
    );
    financeService
      .getFaq()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_fAQ_SUCCESS,
                data: response.Result.faqs,
              })
            );
            // alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_fAQ_FAILURE,
                data: null,
              })
            );
            // alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: financeConstant.GET_fAQ_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getFinanceLogo() {
  // console.log(60, getFinanceLogo);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: financeConstant.GET_FINANCELOGO_REQUEST,
        data: null,
      })
    );
    financeService
      .getFinanceLogo()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCELOGO_SUCCESS,
                data: response.Result.financelogo,
              })
            );
            // alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCELOGO_FAILURE,
                data: null,
              })
            );
            // alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: financeConstant.GET_FINANCELOGO_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getFinanceDescription() {
  console.log(60, getFinanceDescription);
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: financeConstant.GET_FINANCEDESCRIPTION_REQUEST,
        data: null,
      })
    );
    financeService
      .getFinanceDescription()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCEDESCRIPTION_SUCCESS,
                data: response.Result.Description,
              })
            );
            // alert.success(response.Message);
          } else {
            dispatch(
              dispatchFunction({
                type: financeConstant.GET_FINANCEDESCRIPTION_FAILURE,
                data: null,
              })
            );
            // alert.error(response.Message);
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: financeConstant.GET_FINANCEDESCRIPTION_FAILURE,
              data: error,
            })
          );
          alert.error("Oops! Something went wrong!!");
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function dispatchFunction(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
