import { pageConstant } from "../_constants/page.constant";
import { pageService } from "../_services/page.service";
import { alert } from "../_utilities/alert";

export const pageAction = {
  getPages,
  getAboutChooseData,
};

function getPages() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: pageConstant.PAGE_REQUEST,
        data: null,
      })
    );
    pageService
      .getPages()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: pageConstant.PAGE_SUCCESS,
                data: response.Result.page,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: pageConstant.PAGE_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: pageConstant.PAGE_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function getAboutChooseData() {
  return (dispatch) => {
    dispatch(
      dispatchFunction({
        type: pageConstant.ABOUTCHOOSE_DATA_REQUEST,
        data: null,
      })
    );
    pageService
      .getAboutChooseData()
      .then(
        (response) => {
          if (response.Status === 200) {
            dispatch(
              dispatchFunction({
                type: pageConstant.ABOUTCHOOSE_DATA_SUCCESS,
                data: response.Result.AboutChoose,
              })
            );
          } else {
            dispatch(
              dispatchFunction({
                type: pageConstant.ABOUTCHOOSE_DATA_FAILURE,
                data: null,
              })
            );
          }
        },
        (error) => {
          dispatch(
            dispatchFunction({
              type: pageConstant.ABOUTCHOOSE_DATA_FAILURE,
              data: error,
            })
          );
        }
      )
      .catch(() => {
        alert.error("Oops! Something went wrong!!");
      });
  };
}

function dispatchFunction(data) {
  console.log(149, data);
  return {
    type: data.type,
    data: data.data,
  };
}
