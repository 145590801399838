import React, { useState, useEffect } from "react";
import CustomLoader from "../../components/CustomeLoader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { useLocation, useNavigate } from "react-router";
import { configs } from "../../config";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "../../_actions";
import { status } from "../../_constants/commonLhd";

function AboutHistory() {
  const [loader, setLoading] = useState(true);
  const location = useLocation();
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const page = location.state;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 600);
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = (aboutHistoryButtonLink) => {
    if (aboutHistoryButtonLink.startsWith("http")) {
      window.open(aboutHistoryButtonLink, "_blank");
    } else {
      navigate(aboutHistoryButtonLink);
    }
  };

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeAction.getBrandsByTenantId());
  }, []);

  useEffect(() => {
    if (homeData && homeData.brands_status === status.SUCCESS) {
      setBrands(homeData.brands_data);
    }
  }, [homeData.brands_data]);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="main-wrapper">
          <div className="about-col-section first">
            <div className="col-text blue-bg order-last">
              <div className="our-courses-box">
                <div className="date-title">
                  <h1>{page && page.aboutHistoryTitle}</h1>
                  {parse(page && page.aboutHistoryDescription)}
                  <button
                    className="primary-btn sm"
                    onClick={() =>
                      handleButtonClick(page.aboutHistoryButtonLink)
                    }
                  >
                    {page && page.aboutHistoryTextButton}
                    <div className="arrow-icon"></div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-image">
              <img
                src={`${configs.localApiUrl}${page && page.aboutHistoryImage}`}
                alt="home-col-1"
              />
            </div>
          </div>
          <div className="about-list-section second">
            <div className="container">
              <div className="about-item-box">
                <div className="about-list-box">
                  {parse(page && page.aboutHistoryBlog1)}
                </div>
                <div className="about-list-img">
                  <img
                    src={`${configs.localApiUrl}${
                      page && page.aboutHistoryImage1
                    }`}
                    alt="about-col-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="brands-stock text-center">
            <div className="head-title">
              <h2>BRANDS WE WORK WITH</h2>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={400}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
                className="sider-left-scroll"
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                      key={brands.id}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                paceBetween={50}
                slidesPerView={7.5}
                speed={400}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }}
                modules={[Autoplay]}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 4.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 5.5,
                    spaceBetween: 20,
                  },
                  800: {
                    slidesPerView: 7.4,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 7.5,
                    spaceBetween: 20,
                  },
                }}
                className="slider-right-scroll"
              >
                {brands.map((brands) => (
                  <SwiperSlide>
                    <img
                      src={`${configs.localApiUrl}${brands.brandImage}`}
                      alt="Slide 1"
                      key={brands.id}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AboutHistory;
