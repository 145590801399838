export const homeConstant = {
  GET_CARBYID_REQUEST: "GET_CARBYID_REQUEST",
  GET_CARBYID_SUCCESS: "GET_CARBYID_SUCCESS",
  GET_CARBYID_FAILURE: "GET_CARBYID_FAILURE",

  GET_ALLBRANDS_REQUEST: "GET_ALLBRANDS_REQUEST",
  GET_ALLBRANDS_SUCCESS: "GET_ALLBRANDS_SUCCESS",
  GET_ALLBRANDS_FAILURE: "GET_ALLBRANDS_FAILURE",

  CAR_INQUIRY_REQUEST: "CAR_INQUIRY_REQUEST",
  CAR_INQUIRY_SUCCESS: "CAR_INQUIRY_SUCCESS",
  CAR_INQUIRY_FAILURE: "CAR_INQUIRY_FAILURE",

  EMAIL_REQUEST: "EMAIL_REQUEST",
  EMAIL_SUCCESS: "EMAIL_SUCCESS",
  EMAIL_FAILURE: "EMAIL_FAILURE",

  TEST_DRIVE_REQUEST: "TEST_DRIVE_REQUEST",
  TEST_DRIVE_SUCCESS: "TEST_DRIVE_SUCCESS",
  TEST_DRIVE_FAILURE: "TEST_DRIVE_FAILURE",

  TOP_BANNER_REQUEST: "TOP_BANNER_REQUEST",
  TOP_BANNER_SUCCESS: "TOP_BANNER_SUCCESS",
  TOP_BANNER_FAILURE: "TOP_BANNER_FAILURE",

  FETCH_CAR_DETAILS_REQUEST: "FETCH_CAR_DETAILS_REQUEST",
  FETCH_CAR_DETAILS_SUCCESS: "FETCH_CAR_DETAILS_SUCCESS",
  FETCH_CAR_DETAILS_FAILURE: "FETCH_CAR_DETAILS_FAILURE",
};
